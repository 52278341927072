import React from 'react'
import Tiles from '../../../react4xp/components/Tiles'
import Card from '../../../react4xp/components/Card'
import SectionHeader from '../../../react4xp/components/SectionHeader'

function JobOfferingsPart({ heading, intro, error, items, filters, selectedLocation, numberOfItems, strings }) {
  if (error) {
    return <div>{error}</div>
  }
  return (
    <Tiles
      sectionHeader={
        <SectionHeader
          layout='vertical'
          title={heading}
          subtitle={intro}
          bulletColor='accent-1'
          strings={strings}
        />
      }
      maxCards={numberOfItems}
      filters={filters}
      selectedLocation={selectedLocation}
      cards={items && items.map(item => (
        <Card
          key={item.path}
          buttonText={strings.more}
          href={item.href}
          imgSrc={item.imageSrc?.link}
          imgSrcMedium={item.imageSrcMedium?.link}
          imgAlt={item.imageSrc?.alt}
          imgWidth={960}
          title={item.name}
          ingress={item.intro}
          type={item.filterName}
          isChildrenListCard
        />
      ))}
    />
  )
}

export default (props) => <JobOfferingsPart {...props} />
